
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import {
  createProps,
  updateProps,
  getPropsTags
} from '@/api/index'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { filterKeys } from '@/hooks/common-hooks'
import uploadImage from '@/views/courseManger/componets/upload-image.vue'
import { SEND_TYPE, OSSURL } from '@/hooks/ali-oss'
import { DeleteOutlined, PlusCircleOutlined, SwapOutlined, RightOutlined, DownOutlined } from '@ant-design/icons-vue'
import uploadAb from '@/views/encyclopediasManger/components/upload-ab.vue'
interface FormProps {
  prop_code: string;
  prop: string;
  remarks: string;
  tag_id: number | null;
}
export interface FileProps {
  file?: object;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
export default defineComponent({
  name: 'createProps',
  props: ['id'], // 项目id
  components: {
    uploadImage,
    // SwapOutlined,
    uploadAb,
    // DeleteOutlined,
    // PlusCircleOutlined,
    RightOutlined,
    DownOutlined
  },
  emits: ['changeIndex'],
  setup(props, context) {
    const router = useRouter()
    const formRef = ref()
    const formState: FormProps = reactive({
      prop_code: '',
      prop: '',
      remarks: '',
      tag_id: null
    })
    const rules = {
      prop: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入道具名称',
          whitespace: true
        }
      ],
      prop_code: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入道具编号',
          whitespace: true
        }
      ],
      tag_id: [
        { required: true, message: '请选择道具标签', trigger: 'change', type: 'number' }
      ]
    }
    const tagList = ref([])
    // 获取道具标签
    const getPropsTagsHttp = () => {
      getPropsTags({}).then(res => {
        tagList.value = res.data
      })
    }
    const upload1 = ref()
    const uploadAbw = ref()
    const uploadAbi = ref()
    const uploadAbu = ref()
    const uploadAba = ref()
    const listIcon: FileProps = ref([])
    const upObj = ref([SEND_TYPE[1]])
    const changeFileIcon = (lists: []) => {
      listIcon.value = lists
      console.log(listIcon.value, '222')
    }
    const resetListIcon = () => {
      listIcon.value.pop()
    }
    const iconUrl = computed(() => {
      if (listIcon.value.length === 0) {
        return ''
      } else {
        return (listIcon.value[0] as FileProps).file_path
      }
    })
    const upAbwList = ref([])
    const upAbaList = ref([])
    const upAbiList = ref([])
    const upAbuList = ref([])
    // ab包
    const abwIsOpen = ref(false)
    const openAbwContent = () => {
      abwIsOpen.value = !abwIsOpen.value
    }
    const abaIsOpen = ref(false)
    const openAbaContent = () => {
      abaIsOpen.value = !abaIsOpen.value
    }
    const abiIsOpen = ref(false)
    const openAbiContent = () => {
      abiIsOpen.value = !abiIsOpen.value
    }
    const abuIsOpen = ref(false)
    const openAbuContent = () => {
      abuIsOpen.value = !abuIsOpen.value
    }

    const abwList: any = ref([])
    const abwUpObj = ref([SEND_TYPE[5]])
    const changeFile = (lists: any, oladList: any, upLoadList: any) => {
      const isUploaded = oladList.every((item: any) => Object.prototype.hasOwnProperty.call(item, 'id'))
      if (isUploaded) {
        oladList.unshift(...lists)
      } else {
        oladList.splice(0, 1, ...lists)
      }
      upLoadList = lists
      console.log(upLoadList, '123')
    }
    const handleDelete = (index: number, oladList: any, upLoadList: any) => {
      oladList.splice(index, 1)
      upLoadList.splice(0, 1)
    }

    const abaList = ref([])
    const abaUpObj = ref([SEND_TYPE[6]])

    const abiList = ref([])
    const abiUpObj = ref([SEND_TYPE[7]])
    const abuList = ref([])
    const abuUpObj = ref([SEND_TYPE[9]])

    // 删除文件
    const deleteFile = (list: FileProps, item: number) => {
      const _index = list.findIndex((ele: any) => ele === item)
      list.splice(_index, 1)
    }
    // 重置list值
    const resetList = (lists: any) => {
      return lists.map((ele: FileProps) => {
        return {
          type: ele.file_type,
          file_name: ele.file_name ? ele.file_name : ele.name,
          file_path: ele.file_path,
          add_time: ele.add_time
        }
      })[0]
    }
    // 创建
    const fetchCreatProps = () => {
      createProps(Object.assign(formState, {
        icon_path: iconUrl.value,
        abw: upAbwList.value.length > 0 ? JSON.stringify(resetList(upAbwList.value)) : '',
        aba: upAbaList.value.length > 0 ? JSON.stringify(resetList(upAbaList.value)) : '',
        abi: upAbiList.value.length > 0 ? JSON.stringify(resetList(upAbiList.value)) : '',
        abu: upAbuList.value.length > 0 ? JSON.stringify(resetList(upAbuList.value)) : ''
      })).then(res => {
        message.success('创建成功')
        context.emit('changeIndex', 0)
      })
    }
    // 编辑
    const fetchEditprops = () => {
      updateProps(Object.assign(formState, {
        id: props.id,
        icon_path: iconUrl.value,
        abw: upAbwList.value.length > 0 ? JSON.stringify(resetList(upAbwList.value)) : JSON.stringify(resetList(abwList.value)),
        aba: upAbaList.value.length > 0 ? JSON.stringify(resetList(upAbaList.value)) : JSON.stringify(resetList(abaList.value)),
        abi: upAbiList.value.length > 0 ? JSON.stringify(resetList(upAbiList.value)) : JSON.stringify(resetList(abiList.value)),
        abu: upAbuList.value.length > 0 ? JSON.stringify(resetList(upAbuList.value)) : JSON.stringify(resetList(abuList.value))
      })).then(res => {
        message.success('修改道具信息成功')
        router.replace({ name: 'PropManager' })
      })
    }
    const fetchSave = async (callback: () => {}) => {
      if (upAbwList.value.length === 0 && upAbiList.value.length === 0 && upAbaList.value.length === 0 && upAbuList.value.length === 0) {
        callback()
      } else {
        if (upAbwList.value.length) {
          await uploadAbw.value.fetchSend()
        }
        if (upAbiList.value.length) {
          await uploadAbi.value.fetchSend()
        }
        if (upAbaList.value.length) {
          await uploadAba.value.fetchSend()
        }
        if (upAbuList.value.length) {
          await uploadAbu.value.fetchSend()
        }
        callback()
      }
    }
    const onSubmit = (callback: () => {}) => {
      formRef.value
        .validate()
        .then(() => {
          if (listIcon.value.length > 0) {
            if (listIcon.value[0].file) {
              upload1.value.fetchSend()
            }
          }
          fetchSave(callback)
        })
        .catch((error: ValidateErrorEntity<FormProps>) => {
          console.log('error', error)
        })
    }
    // 复制下载地址
    const copyPath = (shareLink: any) => {
      const input = document.createElement('input')
      input.value = shareLink
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      message.success('复制成功')
    }
    onMounted(() => {
      getPropsTagsHttp()
      if (props.id && sessionStorage.getItem('propObj')) {
        const _obj = JSON.parse(sessionStorage.getItem('propObj') as string)
        filterKeys(formState, _obj)
        const _item1 = _obj.icon_path ? [{
          url: OSSURL + '/' + _obj.icon_path,
          file_type: 'p1',
          file_path: _obj.icon_path
        }] : []
        listIcon.value = _item1
        if (_obj.aba && _obj.aba !== '[]') abaList.value.push(JSON.parse(_obj.aba) as never)
        if (_obj.abw && _obj.abw !== '[]') abwList.value.push(JSON.parse(_obj.abw) as never)
        if (_obj.abi && _obj.abi !== '[]') abiList.value.push(JSON.parse(_obj.abi) as never)
        if (_obj.abu && _obj.abu !== '[]') abuList.value.push(JSON.parse(_obj.abu) as never)
      }
    })
    return {
      OSSURL,
      formRef,
      formState,
      rules,
      onSubmit,
      fetchCreatProps,
      fetchEditprops,
      listIcon,
      upObj,
      changeFileIcon,
      resetListIcon,
      uploadAba,
      uploadAbi,
      uploadAbu,
      uploadAbw,
      abiUpObj,
      abuUpObj,
      abiList,
      abuList,
      abaUpObj,
      abaList,
      abwUpObj,
      abwList,
      deleteFile,
      abwIsOpen,
      openAbwContent,
      abaIsOpen,
      openAbaContent,
      abiIsOpen,
      openAbiContent,
      abuIsOpen,
      openAbuContent,
      handleDelete,
      changeFile,
      upAbwList,
      upAbaList,
      upAbiList,
      upAbuList,
      copyPath,
      upload1,
      tagList
    }
  }
})
